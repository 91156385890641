<template>
    <div>
        <el-form ref="queryForm" class="query-box query-box2" v-model="queryParams" label-width="68px">
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="交易时间" prop="name">
                        <el-date-picker size="small"
                            v-model="dateVal"
                            type="daterange"
                            range-separator="-"
                            value-format="yyyy-MM-dd"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            style="width: 100%;">
                            </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="订单号" prop="outOrderNo" label-width="92px">
                        <el-input v-model="queryParams.outOrderNo"
                                  clearable
                                  placeholder="请输入订单号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="商户编号" prop="merchantNo" label-width="82px">
                        <el-input v-model="queryParams.merchantNo"
                                  clearable
                                  placeholder="请输入商户编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="SN号" prop="name">
                        <el-input v-model="queryParams.sn"
                                  clearable
                                  placeholder="请输入SN号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
				<el-col :span="6">
                    <el-form-item label="渠道" prop="channelNo">
                        <el-select v-model="queryParams.channelNo"
                                   placeholder="渠道" clearable size="small" style="width: 100%">
                            <el-option label="全部" value=""/>
                            <el-option v-for="(item,key) of dicts.CHANNEL" :key="key" :label="item" :value="key" />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="卡号(末四位)" prop="name" label-width="92px">
                        <el-input v-model="queryParams.accountNo"
                                  clearable
                                  placeholder="请输入卡号(末四位)"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="6" v-if="isOrg">
                    <el-form-item label="机构编号" prop="name">
                        <el-input v-model="queryParams.orgNo"
                                  clearable
                                  placeholder="请输入机构编号"
                                  size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col> -->
                <el-col :span="6">
                    <el-form-item label="代理商编号" prop="name" label-width="82px">
                        <el-input v-model="queryParams.agentNo"
                                clearable
                                placeholder="请输入代理商编号"
                                size="small" @keyup.enter.native="handleQuery"/>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="支付类型" prop="name">
                        <el-select v-model="queryParams.payTypeCode" placeholder="请选择支付类型" size="small" style="width: 100%">
                            <el-option v-for="(value,key) in dicts.PAY_TYPE" :key="key" :label="value" :value="key"></el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-form-item label="交易状态" prop="name">
                        <el-select v-model="queryParams.transStatus" placeholder="请选择交易状态" style="width: 100%">
                            <el-option label="全部" value=""></el-option>
                            <el-option v-for="(value,key) in dicts.TRANS_STATUS" :key="key" :label="value" :value="key"/>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5">
                <el-button type="primary" icon="el-icon-search" size="mini"
                           @click="handleQuery" v-permission="'TRANS_ORDER_QUERY'">
                    查询
                </el-button>
                <el-button icon="el-icon-refresh" size="mini"
                           @click="handleResetQuery" v-permission="'TRANS_ORDER_QUERY'">
                    重置
                </el-button>
                <el-button icon="el-icon-download" size="mini" @click="handleExport" v-permission="'TRANS_ORDER_EXPORT'">
                    导出
                </el-button>
            </el-col>
        </el-row>

    </div>
</template>

<script>
    import {TradeApi} from '@/api';
    import { mapState } from 'vuex'
    export default {
        name: "ToolBar",
        props: {
            dicts: {
                type: Object,
                default: {}
            },
        },
        data() {
            return {
                queryParams: {},
                createDateRange: [],
                updateDateRange: [],
                showCustomForm: false,
                dateVal: '',
            }
        },
        computed:{
            ...mapState('app', ['userInfo']),
            isOrg(){
                return this.userInfo.org
            },
        },
        methods: {
            handlerCustomFormOnclose() {
                this.showCustomForm = false;
                this.commitChange();
            },
            handleQuery() {
                this.commitChange();
            },
            handleResetQuery(){
                this.queryParams = {};
                this.createDateRange = [];
                this.updateDateRange = [];
                this.dateVal = []
                this.commitChange();
            },
            handleAdd(){
                this.showCustomForm = true;
            },
            handleExport() {
                this.$confirm("确定导出交易订单",
                        "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(async () => {
                    this.dealWithQueryParams();
                    let result = (await TradeApi.transOrder.export(this.queryParams));
                    if (result.success) {
                        this.downloadFile(result.data);
                    }
                });
            },
            dealWithQueryParams() {
                if(!this.dateVal){
                    this.dateVal =[]
                }
                let [beginTransTime, endTransTime] = this.dateVal;
                this.queryParams.beginTransTime = beginTransTime && (beginTransTime + ' 00:00:00');
                this.queryParams.endTransTime = endTransTime && (endTransTime + ' 23:59:59');

                let [startUpdateTime, endUpdateTime] = this.updateDateRange;
                this.queryParams.startUpdateTime = startUpdateTime && (startUpdateTime + ' 00:00:00');
                this.queryParams.endUpdateTime = endUpdateTime && (endUpdateTime + ' 23:59:59');
            },
            commitChange() {
                this.dealWithQueryParams();
                let params = {...this.queryParams};
                this.$emit('on-change', params);
            },
        }
    }
</script>

<style scoped>

</style>
